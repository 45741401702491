export const QuestionTypeComponentsMixin = {
  components: {
    ErrorMessage: () => import("@/components/ErrorMessage"),
    Policy: () => import("@/components/Policy"),
    Reward: () => import("@/components/Reward"),
    Summary: () => import("@/components/Summary"),
    SandboxHtml: () => import("@/components/SandboxHtml"),
    QSL: () => import("@/components/SelectLanguage"),
    QSA: () => import("@/components/SingleAnswer"),
    QMA: () => import("@/components/MultipleAnswer"),
    QFA: () => import("@/components/FreeAnswer"),
    QNI: () => import("@/components/NumberInput"),
    QTD: () => import("@/components/TextDisplay"),
    QSAM: () => import("@/components/SingleAnswerMatrix"),
    QDL: () => import("@/components/DropdownList"),
    QS: () => import("@/components/Satisfaction"),
    QDT: () => import("@/components/DateTimePicker"),
    QMI: () => import("@/components/MultipleInput"),
    QFU: () => import("@/components/FileUpload"),
    QR: () => import("@/components/Ranking"),
    QSO: () => import("@/components/ScreenOut"),
    QLL: () => import("@/components/LatLong"),
    QC: () => import("@/components/Credential"),
    QQF: () => import("@/components/QuotaFull"),
    QMAM: () => import("@/components/MultipleAnswerMatrix"),
  },
};
